import React from "react"

import Layout from "../components/layout"

const Privacy = () => (
  <Layout>
    <div className="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-terms-privacy">
              <h1>سياسة الخصوصية</h1>
              <p>
                {" "}
                في نشرة ، يمكن الوصول إليه من https://www.nshra.com ، إحدى
                أولوياتنا الرئيسية هي خصوصية زوارنا. تحتوي وثيقة سياسة الخصوصية
                هذه على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة نشرة
                وكيفية استخدامنا لها.{" "}
              </p>

              <p>
                {" "}
                إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول
                سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا.{" "}
              </p>

              <p>
                {" "}
                تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة
                لزوار موقعنا على الويب فيما يتعلق بالمعلومات التي شاركوها و / أو
                يجمعونها في نشرة. لا تنطبق هذه السياسة على أي معلومات يتم جمعها
                في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع.
              </p>

              <h2> الموافقة </h2>

              <p>
                {" "}
                باستخدام موقعنا ، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا
                وتوافق على شروطها. للاطلاع على البنود والشروط الخاصة بنا ،
              </p>

              <h2> المعلومات التي نجمعها </h2>

              <p>
                {" "}
                سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها ، وأسباب
                مطالبتك بتقديمها ، عند النقطة التي نطلب منك فيها تقديم معلوماتك
                الشخصية.{" "}
              </p>
              <p>
                {" "}
                إذا اتصلت بنا مباشرةً ، فقد نتلقى معلومات إضافية عنك مثل اسمك
                وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و / أو
                المرفقات التي قد ترسلها إلينا وأي معلومات أخرى قد تختار تقديمها
                .{" "}
              </p>
              <p>
                {" "}
                عند التسجيل للحصول على حساب ، قد نطلب معلومات الاتصال الخاصة بك
                ، بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد
                الإلكتروني ورقم الهاتف.{" "}
              </p>

              <h2> كيف نستخدم معلوماتك </h2>

              <p> نستخدم المعلومات التي نجمعها بطرق مختلفة ، بما في ذلك: </p>

              <ul>
                <li> توفير موقع الويب الخاص بنا وتشغيله وصيانته </li>
                <li> تحسين موقعنا على الويب وتخصيصه وتوسيعه </li>
                <li> فهم وتحليل كيفية استخدامك لموقع الويب الخاص بنا </li>
                <li> تطوير منتجات وخدمات وميزات ووظائف جديدة </li>
                <li>
                  {" "}
                  التواصل معك ، إما بشكل مباشر أو من خلال أحد شركائنا ، بما في
                  ذلك لخدمة العملاء ، لتزويدك بالتحديثات والمعلومات الأخرى
                  المتعلقة بموقع الويب ، ولأغراض تسويقية وترويجية{" "}
                </li>
                <li> إرسال رسائل بريد إلكتروني إليك </li>
                <li> البحث عن الاحتيال ومنعه </li>
              </ul>

              <h2> ملفات الدخول </h2>

              <p>
                {" "}
                تتبع نشرة إجراءً قياسيًا لاستخدام ملفات السجل. تسجل هذه الملفات
                الزوار عندما يزورون مواقع الويب. تقوم جميع شركات الاستضافة بذلك
                وجزء من تحليلات خدمات الاستضافة. تتضمن المعلومات التي يتم جمعها
                بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع المستعرض
                وموفر خدمة الإنترنت (ISP) وطابع التاريخ والوقت وصفحات الإحالة /
                الخروج وربما عدد النقرات. هذه ليست مرتبطة بأي معلومات لتحديد
                الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات وإدارة
                الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات
                الديموغرافية.{" "}
              </p>

              <h2> ملفات تعريف الارتباط وإشارات الويب </h2>

              <p>
                {" "}
                مثل أي موقع ويب آخر ، تستخدم نشرة ملفات تعريف الارتباط. تُستخدم
                ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك تفضيلات
                الزوار والصفحات الموجودة على موقع الويب التي وصل إليها الزائر أو
                زارها. تُستخدم المعلومات لتحسين تجربة المستخدمين من خلال تخصيص
                محتوى صفحة الويب الخاصة بنا بناءً على نوع متصفح الزوار و / أو
                معلومات أخرى.{" "}
              </p>

              <h2> سياسات خصوصية شركاء الإعلانات </h2>

              <p>
                {" "}
                يمكنك الرجوع إلى هذه القائمة للعثور على سياسة الخصوصية لكل من
                شركاء الإعلان في نشرة.{" "}
              </p>

              <p>
                {" "}
                تستخدم خوادم الإعلانات الخارجية أو شبكات الإعلانات تقنيات مثل
                ملفات تعريف الارتباط أو جافا سكريبت أو منارات الويب المستخدمة في
                الإعلانات والروابط الخاصة بكل منها والتي تظهر على موقع نشرة ،
                والتي يتم إرسالها مباشرة إلى متصفح المستخدمين. يتلقون عنوان IP
                الخاص بك تلقائيًا عند حدوث ذلك. تُستخدم هذه التقنيات لقياس
                فعالية حملاتهم الإعلانية و / أو لتخصيص محتوى الإعلان الذي تراه
                على مواقع الويب التي تزورها.{" "}
              </p>

              <p>
                {" "}
                لاحظ أن نشرة ليس لديها حق الوصول أو التحكم في ملفات تعريف
                الارتباط هذه التي يستخدمها معلنون من جهات خارجية.{" "}
              </p>

              <h2> سياسات خصوصية الطرف الثالث </h2>

              <p>
                {" "}
                لا تنطبق سياسة خصوصية نشرة على المعلنين أو مواقع الويب الأخرى.
                وبالتالي ، فإننا ننصحك بالرجوع إلى سياسات الخصوصية الخاصة بخوادم
                إعلانات الجهات الخارجية للحصول على معلومات أكثر تفصيلاً. قد
                يتضمن ممارساتهم وتعليماتهم حول كيفية إلغاء الاشتراك في خيارات
                معينة.{" "}
              </p>

              <p>
                {" "}
                يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال خيارات المتصفح
                الفردية. لمعرفة المزيد من المعلومات التفصيلية حول إدارة ملفات
                تعريف الارتباط مع متصفحات الويب المحددة ، يمكن العثور عليها في
                مواقع الويب الخاصة بالمتصفحات.{" "}
              </p>

              <h2> حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية) </h2>

              <p>
                {" "}
                بموجب قانون حماية خصوصية المستهلك في كاليفورنيا ، من بين حقوق
                أخرى ، يحق للمستهلكين في كاليفورنيا ما يلي:{" "}
              </p>
              <p>
                {" "}
                اطلب من الشركة التي تجمع البيانات الشخصية للمستهلك أن تفصح عن
                فئات وأجزاء معينة من البيانات الشخصية التي جمعتها الشركة عن
                المستهلكين.{" "}
              </p>
              <p>
                {" "}
                اطلب من شركة ما حذف أي بيانات شخصية عن المستهلك جمعتها الشركة.{" "}
              </p>
              <p>
                {" "}
                اطلب من الشركة التي تبيع البيانات الشخصية للمستهلك ألا تبيع
                البيانات الشخصية للمستهلك.{" "}
              </p>
              <p>
                {" "}
                إذا قدمت طلبًا ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في
                ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.{" "}
              </p>

              <h2> حقوق حماية بيانات GDPR </h2>

              <p>
                {" "}
                نود التأكد من أنك على دراية كاملة بجميع حقوق حماية البيانات
                الخاصة بك. يحق لكل مستخدم ما يلي:{" "}
              </p>
              <p>
                {" "}
                الحق في الوصول - يحق لك طلب نسخ من بياناتك الشخصية. قد نفرض عليك
                رسومًا رمزية مقابل هذه الخدمة.{" "}
              </p>
              <p>
                {" "}
                الحق في التصحيح - لك الحق في طلب تصحيح أي معلومات تعتقد أنها غير
                دقيقة. لديك أيضًا الحق في طلب إكمال المعلومات التي تعتقد أنها
                غير كاملة.{" "}
              </p>
              <p>
                {" "}
                الحق في المحو - يحق لك طلب مسح بياناتك الشخصية ، في ظل ظروف
                معينة.{" "}
              </p>
              <p>
                {" "}
                الحق في تقييد المعالجة - يحق لك طلب تقييد معالجة بياناتك الشخصية
                ، في ظل ظروف معينة.{" "}
              </p>
              <p>
                {" "}
                الحق في الاعتراض على المعالجة - يحق لك الاعتراض على معالجتنا
                لبياناتك الشخصية ، في ظل ظروف معينة.{" "}
              </p>
              <p>
                {" "}
                الحق في نقل البيانات - يحق لك أن تطلب منا نقل البيانات التي
                جمعناها إلى مؤسسة أخرى ، أو إليك مباشرةً ، في ظل ظروف معينة.{" "}
              </p>
              <p>
                {" "}
                إذا قدمت طلبًا ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في
                ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.{" "}
              </p>

              <h2> معلومات الأطفال </h2>

              <p>
                {" "}
                يتمثل جزء آخر من أولوياتنا في إضافة الحماية للأطفال أثناء
                استخدام الإنترنت. نحن نشجع الآباء والأوصياء على مراقبة نشاطهم
                عبر الإنترنت والمشاركة فيه و / أو مراقبته وتوجيهه.{" "}
              </p>

              <p>
                {" "}
                لا تجمع نشرة عن قصد أي معلومات تعريف شخصية من الأطفال دون سن 13
                عامًا. إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على
                موقعنا ، فنحن نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى
                جهدنا لإزالة هذه المعلومات على الفور من سجلاتنا.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Privacy
